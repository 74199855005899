<template>
  <div class="px-5">
    <div class="text-lg flex items-center my-3 txt-grey-900">
      <span
        class="cursor-pointer fw-500 txt-grey-700 hover:underline"
        @click="handleBackSubOrgList"
        >{{ org.name }}</span
      >
      <img
        class="mx-2"
        src="../../assets/images/keyboard_arrow_right.png"
        alt="keyboard_arrow_right"
      />
      <span>{{ $t("workspace_setting.lbl_list_workspace")[$orgModel] }}</span>
    </div>
    <OrganizationTableWS :orgId="orgId" />
  </div>
</template>

<script>
import OrganizationTableWS from '@/components/OrganizationTableWS.vue'

export default {
  name: 'OrganizationListWs',
  components: { OrganizationTableWS },
  data () {
    return {
      org: {},
      orgId: 0
    }
  },
  beforeMount () {
    this.orgId = this.$globalOrg?.id
  },
  mounted () {
    this.getOrg(this.orgId)
  },
  methods: {
    async getOrg (id) {
      if (!id) return
      const res = await this.$rf.getRequest('DoctorRequest').getOrg(id)
      this.org = res.data
    },

    handleBackSubOrgList () {
      this.$router.push({
        name: 'OrganizationManagement'
      })
    },

    async deleteWs (ws) {
      if (!this.checkRoleOwnerOrg(this.org || this.$user)) {
        alert('Bạn không có quyền xóa nhân sự trong Org này')
        return
      }
      if (confirm(`Bạn muốn xóa ${ws.name}`)) {
        await this.deleteOrgMembers(ws.id)
      }
    }
  }
}
</script>

<style scoped>
.txt-grey-1000 {
  color: #52575c !important;
}
</style>
